/* Custom CSS for Date Picker */
.custom-date-picker input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ced4da;
    background-color: #f8f9fa;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
}

.custom-date-picker input:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.react-datepicker {
    font-size: 16px;
}

.react-datepicker__header {
    background-color: #007bff;
    color: white;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
    background-color: #007bff;
    color: white;
}

.react-datepicker__day:hover {
    background-color: #0056b3;
    color: white;
}

.form-group {
    margin-bottom: 15px; /* Ajoute de l'espace entre les champs */
}

.start-date-label {
    margin-right: 10px; /* Espace entre le label et le champ */
}

.start-date-input {
    margin-right: 20px; /* Espace entre le champ Start Date et le champ suivant */
}
