

.btn-primary-outline {
	background-color: transparent;
	border-color: #ccc;
  }



.dashboard {
	overflow: hidden;
}
.custom-nav-item {
	height: 50px;
	display: flex;
	flex-wrap: nowrap;
	flex-direction: column;
	overflow: hidden;
	cursor: pointer;
}
.custom-nav-item:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

.custom-nav-item a {
	color: #2f2f2f;
	text-decoration: none;
}
.custom-nav-item a:hover {
	color: #2f2f2f;
	text-decoration: none;
}
.collapsed .custom-nav-item {
	width: 50px;
	overflow: hidden;
}

.icon-container {
	width: 50px;
	height: 50px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

/* Styles.css or your specific CSS file */
.bold-and-large {
    font-weight: bold;
    font-size: 1.5em; /* Adjust size as needed */
}

@keyframes flash {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
}

.flashing {
    animation: flash 2s linear infinite;
}


@media (max-width: 425px) {
	.dashboard {
		min-width: 270px;
	}
}


.invoice-modal .table th,
.invoice-modal .table td {
  text-align: center; /* Center align text in th and td */
}

.invoice-modal .modal-header {
  background-color: #007bff;
  color: white;
}

.invoice-modal .modal-footer {
  background-color: #f8f9fa;
}

.invoice-modal .table {
  margin-bottom: 0; /* Remove margin bottom from the table */
}

.invoice-modal th {
  background-color: #007bff;
  color: white;
}

.text-paid {
	color: green;
  }
  
  .text-unpaid {
	color: red;
  }

  .popup-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.5);
	z-index: 1000;
  }
  
  .popup-content {
	background: white;
	padding: 40px;
	border-radius: 10px;
	position: relative;
	text-align: center;
  }
  
  .popup-image {
	width: 100%;
	max-width: 600px;
	height: auto;
  }
  
  .close-button {
	position: absolute;
	top: 10px;
	right: 10px;
	background: transparent;
	border: none;
	font-size: 24px;
	cursor: pointer;
	color: #333;  /* Couleur par défaut */
	transition: color 0.3s ease;  /* Transition douce */
  }
  
  .close-button:hover {
	color: #ff0000;  /* Change la couleur au survol */
  }
  
  .close-button i {
	font-size: 24px;  /* Taille de l'icône */
  }

  
  
