/* Styles for custom labels */
.custom-label {
    font-weight: bold;
    font-size: 1.2rem;
    color: #333; /* Text color */
    margin-bottom: 8px;
  }
  
  /* Styles for custom buttons */
  .custom-button {
    background-color: #007bff; /* Button background color */
    color: #fff; /* Text color */
    border: none;
    font-size: 1rem;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .custom-button:hover {
    background-color: #0056b3; /* Button background color on hover */
  }
  
  /* Styles for custom links */
  .custom-link {
    color: #007bff; /* Link color */
    text-decoration: none;
  }
  
  .custom-link:hover {
    text-decoration: underline;
  }
  
  /* Styles for pagination buttons */
  .pagination-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .pagination-buttons button {
    background-color: #007bff;
    color: #fff;
    border: none;
    font-size: 1rem;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .pagination-buttons button:hover {
    background-color: #0056b3;
  }
  
  /* Disable previous button when on the first page */
  .pagination-buttons button:first-child:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Disable next button when on the last page */
  .pagination-buttons button:last-child:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  