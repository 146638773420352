.planning-container {
	max-width: 800px;
	margin: 0 auto;
	padding: 20px;
  }
  
  .calendar-wrapper {
	display: flex;
  }
  
  .calendar {
	flex: 1;
	margin-right: 20px;
  }
  
  .form-wrapper {
	flex: 1;
  }
  
  .form-group {
	margin-bottom: 15px;
  }
  
  .form-control {
	width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
  }
  
  .btn {
	display: inline-block;
	padding: 10px 20px;
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
  }
  
  .btn-primary {
	background-color: #007bff;
  }
  
  .btn-primary:hover {
	background-color: #0056b3;
  }

  .calendar-wrapper {
    grid-column: 1;
    display: flex;
    justify-content: center;
  }

  .calendar h3 {
    margin-top: 0; /* Supprimer la marge par défaut du titre */
  }
  
  .form-wrapper {
    grid-column: 2;
    text-align: left;
  }

  /* Ajoutez ceci dans votre fichier PlanningCalendrier.css */
.beautiful-button {
  background-color: #B0D50B; /* Vert */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
  transition: background 0.3s, transform 0.3s;
}

.beautiful-button:hover {
  background-color: #45a049;
  transform: scale(1.1);
}

.filters-container {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Espacement entre chaque élément */
  max-width: 200px; /* Ajustez selon vos besoins */
  margin-left: auto;
  margin-right: auto;
}

.filter-checkbox {
  opacity: 0;
  position: absolute;
}

.filter-label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  display: block;
}

.filter-label:before, .filter-label:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -9px;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  transition: all 0.3s ease;
}

.filter-label:before {
  background-color: #f0f0f0;
  border: 2px solid #d1d1d1;
}

.filter-checkbox:checked + .filter-label:before {
  background-color: #4CAF50; /* Couleur de fond lorsque cochée */
  border-color: #4CAF50; /* Couleur de bordure lorsque cochée */
}

.filter-checkbox:checked + .filter-label:after {
  content: 'X'; /* Symbole de coche */
  color: white;
  font-size: 14px;
  line-height: 1;
  text-align: center;
}


  