/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap'); */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.signLinks li {
  list-style-type: none;
  display: inline;
  margin-right: 10px;
}
.card {
  padding: 0 !important;
}

.round-button {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	margin-left: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
  }

  
.list-item span {
  margin-right: 10px;
}

.list-item button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background-color: #e57373;
  color: #fff;
}

.auth-page {
 /* background: linear-gradient(#0c498a, #75abe5);*/
 background: rgb(14, 75, 139) ;
  font-family: "GTWMedium", sans-serif;
  min-height: 100vh;
}
.white-text {
  color: #fff;
}

.sprint-text {
  color: #B0D50B;
}

.sprint-text_blanc {
  color: #f0eeee;
}


.sprint-text_attendance {
  color: #000000;
  font-size: 20px;
  font-family: "GTWMedium", sans-serif;
  background-color: #B0D50B;
}

a img {
  border: 0;
}

.red-text {
  color: rgb(255, 0, 0);
  font-size: 11px;
}

.card-sprint {
  width: 16rem;
}



.red-text-big {
  color: rgb(255, 0, 0);
  font-size: 15px;
}

.red-text-bigger {
  color: rgb(255, 0, 0);
  font-size: 30px;
}
.btnlogin {background-color: #008CBA;}

.btn-primary-sprint {
  color: #212529;
  background-color: rgb(204, 119, 204);
  border-color: rgb(204, 119, 204)
}

.google-login {
  background: white !important;
  color: black !important;
}
.btn-primary-outline {
	background-color: transparent;
	border-color: rgb(252, 248, 248) !important;
  border: none !important;
  color:rgb(0, 124, 251) !important;
  font-size: 12px !important;
  font-weight: bold !important;
  outline: none  !important;
  }
.facebook-login {
  background: #3b5998 !important;
  color: white !important;
}

.auth-page .card-body {
 /* background: linear-gradient(#3A75B5, #75abe5);*/
 background: white;
 border-radius: 0 0 15px 15px;
  margin: 0;
}
.auth-page .card-head {
  /* background: linear-gradient(#3A75B5, #75abe5);*/
  background: rgb(241, 240, 240);
  border-radius: 16px 16px 0 0;
  font-family: "GTWMedium", sans-serif;
  font-size: 22px;
  line-height: 30px;
  margin-top: 3rem!important;
  margin-bottom: .1rem!important;
   margin: 0;
 }

/* input#email ::placeholder {
  color: white !important;
} */

.form-control {
  background-color: transparent !important;
}



.professor-image {
  height: auto;
  width: 200px;
  margin: 10px auto;
}

.professor-image img, img.professor-image {
  width: 200px;
  height: 200px;
  min-height: 200px;
  max-height: 200px;
}

.carousel .slide {
  background: #F5F5F5 !important;
}
.carousel .control-dots .dot {
  background: black !important;
}
.control-arrow .control-prev {
  color: black !important;
}

.carousel.carousel-slider .control-arrow {
  background: grey !important;
}

.carousel .carousel-status {
	font-size: 15px !important;
	color: white !important;
}


/* .carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  color: grey !important;
  background: black !important;
} */
.carousel .control-next.control-arrow {
  color: black !important;
}
.booking-btn {
  margin-top: 20px;
  margin-bottom: 50px !important;
}
.fa-google {
  background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}


.bold {
  font-weight: bold;;
}
/*
.container {
  max-width: 500px;
  margin: 30px auto;
  overflow: auto;
  min-height: 300px;
  border: 1px solid steelblue;
  padding: 30px;
  border-radius: 5px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.btn {
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

.task {
  background: #f4f4f4;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.task.reminder {
  border-left: 5px solid green;
}

.task h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-form {
  margin-bottom: 40px;
}

.form-control {
  margin: 20px 0;
}

.form-control label {
  display: block;
}

.form-control input {
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
}

.form-control-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-control-check label {
  flex: 1;
}

.form-control-check input {
  flex: 2;
  height: 20px;
}

footer {
  margin-top: 30px;
  text-align: center;
} */