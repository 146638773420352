/* SidePanel.css */
.side-panel {
    position: fixed;
    right: -300px; /* Start with the panel outside the viewport */
    width: 300px; /* Set the desired width of the panel */
    height: 100vh; /* Set the height to occupy the full viewport height */
    background-color: #f0f0f0;
    transition: right 0.3s ease; /* Add a transition for smooth sliding animation */
    padding: 20px;
    max-height: 600px; /* Définir une hauteur maximale pour le SidePanel */
  overflow-y: auto; /* Activer le défilement vertical automatique si le contenu dépasse la hauteur maximale */
  }

  .container2 {
    display: flex; /* Utilisez flexbox pour aligner les éléments horizontalement */
  }
  
  .side-panel.open {
    right: 0; /* Slide the panel into the viewport when it's open */
  }

  .other-element {
    flex: 1; /* Faites en sorte que les autres éléments occupent l'espace restant */
    margin-right: 20px; /* Ajoutez de l'espace à droite des autres éléments pour le panneau ouvert */
  }

/* Style for the closed label */
.closed-label {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  white-space: nowrap;
  position: fixed;
  top: 280px;
  right: 0px;
  background-color: #007bff;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transform: translateY(-50%) rotate(180deg);
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease; /* Add a transition for smooth sliding animation */
}
.closed-label-actions {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  white-space: nowrap;
  position: fixed;
  top: 440px;
  right: 0px;
  background-color: #007bff;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transform: translateY(-50%) rotate(180deg);
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease; /* Add a transition for smooth sliding animation */
}

.closed-label-actions.open {
  right: 300px;
}

/* Style for the opened label */
.closed-label.open {
  right: 300px;
}

.closed-label:hover {
  background-color: #0056b3;
}

/* Style pour les éléments de liste */
.side-panel ul {
  list-style-type: disc; /* Utilisez des puces circulaires pour les bullet points */
  padding-left: 20px; /* Ajoute un peu d'espace à gauche pour créer un espacement avec le texte */
}

/* Style individuel pour un élément de liste */
.side-panel li {
  margin-bottom: 5px; /* Ajoute un espacement vertical entre chaque élément de liste */
}