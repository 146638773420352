/* CollapsibleDiv.css */
.collapsible {
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .collapsible button {
    background-color: #f0f0f0;
    border: none;
    padding: 8px 16px;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .collapsible button:hover {
    background-color: #e0e0e0;
  }
  
  
  .collapsible.show .content {
    max-height: 1000px; /* Définissez une valeur appropriée pour la hauteur maximale */
  }
  
  .collapsible .content p {
    margin: 5px 0;
  }
  
/* Améliorer le style de la liste */
.collapsible .content ul {
    list-style-type: none; /* Supprimer les puces par défaut */
    padding: 0;
    margin: 0;
  }
  
  .collapsible .content li {
    margin: 10px 0;
    display: flex; /* Utiliser Flexbox pour aligner les éléments horizontalement */
    align-items: center; /* Aligner verticalement les éléments de la liste */
    padding-left: 20px; /* Ajouter un espacement entre la puce et le bord gauche */
  }

  .collapsible .content ol {
    list-style-type: decimal; /* Utiliser des numéros pour la liste ordonnée */
    padding: 0;
    margin: 0;
  }
  
  .collapsible .content li::before {
    content: "\2022"; /* Utiliser le caractère Unicode pour afficher des puces rondes */
    font-size: 10px; /* Ajuster la taille de la puce */
    margin-right: 10px; /* Ajouter un espacement entre la puce et le texte */
    color: #007bff; /* Couleur de la puce */
  }
  
  /* Améliorer la mise en forme du texte dans la liste */
  .collapsible .content p {
    margin: 0;
    font-size: 14px; /* Ajuster la taille du texte */
    line-height: 1.4; /* Espacement des lignes pour faciliter la lecture */
  }
  
  /* Ajouter une animation de transition pour les éléments de la liste */
  @keyframes fadeInList {
    from {
      opacity: 0;
      transform: translateX(-5px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .collapsible.show .content li {
    animation: fadeInList 0.3s ease-in-out;
  }
  
  /* Personnaliser les icônes pour le bouton */
  .collapsible button::after {
    font-size: 18px;
    margin-left: 10px;
    transition: transform 0.3s;
  }
  
  .collapsible.show button::after {
    content: "\u25BC"; /* Flèche vers le bas lorsque le collapsible est ouvert */
    transform: rotate(90deg);
  }
  
  /* Ajouter une animation de transition pour les collapsibles */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .collapsible.show .content {
    animation: fadeIn 0.5s;
  }